"use strict";

var _interopRequireWildcard = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("C:\\Projects\\028 - Driver\\node_modules\\core-js\\modules\\es.array.iterator.js");
require("C:\\Projects\\028 - Driver\\node_modules\\core-js\\modules\\es.promise.js");
require("C:\\Projects\\028 - Driver\\node_modules\\core-js\\modules\\es.object.assign.js");
require("C:\\Projects\\028 - Driver\\node_modules\\core-js\\modules\\es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/plugins/element");
require("@/plugins/vue-phone-number-input");
require("./icons");
require("./permission");
var _i18n = _interopRequireDefault(require("@/plugins/i18n"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));
var _SingleImageS = _interopRequireDefault(require("@/components/Upload/SingleImageS3.vue"));
var _HeadSection = _interopRequireDefault(require("@/views/application/components/HeadSection.vue"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _InputMask = _interopRequireDefault(require("@/components/InputMoney/InputMask.vue"));
var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));
var _vueOtpInput = _interopRequireDefault(require("@bachdgvn/vue-otp-input"));
var filters = _interopRequireWildcard(require("./filters"));
require("@/plugins/font-awesome-icon");
var _url = require("./utils/url");
// a modern alternative to CSS resets

_locale.default.use(_vi.default);

// global css

//google map
// import  "@/plugins/google-maps";
//
// icon
// permission control
_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$secondToMinutes = _format.secondToMinutes;
_vue.default.prototype.$url = _url.url;
_vue.default.prototype.$cloudFrontUrl = process.env.VUE_APP_CLOUD_FRONT_URL;
_vue.default.component('single-image', _SingleImage.default);
_vue.default.component('SingleImageS3', _SingleImageS.default);
_vue.default.component('head-section', _HeadSection.default);
var VueInputMask = require("vue-inputmask").default;
_vue.default.use(VueInputMask);
_vue.default.component("Pagination", _index2.default);
_vue.default.component('InputMask', _InputMask.default);
_vue.default.component("InputMoney", _InputMoney.default);
_vue.default.component("v-otp-input", _vueOtpInput.default);
// global filters

_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size
});

_vue.default.use(_vueClipboard.default);
// register global utility filters
Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.config.productionTip = false;
Object.defineProperty(_vue.default.prototype, "$tstore", {
  get: function get() {
    return this.$store;
  },
  enumerable: true
});
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  i18n: _i18n.default,
  render: function render(h) {
    return h(_App.default);
  }
});