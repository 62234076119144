"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.handlePermissionRoute() ? _c("div", {
    staticClass: "menu-wrapper"
  }, [_vm.hasOneShowingChild(_vm.item.children, _vm.item) && (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) && !_vm.item.alwaysShow ? [_vm.onlyOneChild.meta ? _c("app-link", {
    attrs: {
      to: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_c("el-menu-item", {
    class: {
      "submenu-title-noDropdown": !_vm.isNest
    },
    attrs: {
      index: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_c("item", {
    attrs: {
      icon: _vm.onlyOneChild.meta.icon || _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.onlyOneChild.meta.title)
    }
  })], 1)], 1) : _vm._e()] : _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.item.meta.title)
    }
  }) : _vm._e()], 1), _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  })], 2)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;