"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "identity-card"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("head-section", {
    attrs: {
      title: "Thẻ Căn Cước / Hộ Chiếu"
    }
  }), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Căn cước công dân mặt Trước",
      prop: "imageIdentityFront"
    }
  }, [_c("SingleImageS3", {
    attrs: {
      pathImage: _vm.form.imageIdentityFront,
      width: 150,
      height: 150
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUpdateSuccess(path, "imageIdentityFront");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Căn cước công dân mặt Sau",
      prop: "imageIdentityBack"
    }
  }, [_c("SingleImageS3", {
    attrs: {
      pathImage: _vm.form.imageIdentityBack,
      width: 150,
      height: 150
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUpdateSuccess(path, "imageIdentityBack");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số Thẻ Căn Cước / Hộ Chiếu",
      prop: "numberIdentity"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.numberIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numberIdentity", $$v);
      },
      expression: "form.numberIdentity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ngày Cấp",
      prop: "dateIdentityProvide"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "dd/mm/yyyy",
      clearable: ""
    },
    model: {
      value: _vm.form.dateIdentityProvide,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateIdentityProvide", $$v);
      },
      expression: "form.dateIdentityProvide"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nơi Cấp",
      prop: "placeIdentityProvide"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.placeIdentityProvide,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "placeIdentityProvide", $$v);
      },
      expression: "form.placeIdentityProvide"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Chỗ ở hiện tại",
      prop: "stayingAddress"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.stayingAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "stayingAddress", $$v);
      },
      expression: "form.stayingAddress"
    }
  })], 1)], 1), !_vm.$tstore.state.user.info.driverProfile.isSubmitted ? _c("el-button", {
    staticClass: "btn-save",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Lưu")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;