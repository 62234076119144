"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.string.link.js");
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "application-page"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("head-application"), _c("div", {
    staticClass: "divider"
  }, [_c("div", {
    ref: "progress",
    staticClass: "progress"
  })]), _c("h2", {
    staticClass: "gray-color"
  }, [_vm._v("Thông tin cá nhân")]), _vm._l(_vm.data, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("section-item", {
      attrs: {
        title: item.label,
        required: item.required,
        completed: item.completed
      },
      nativeOn: {
        click: function click($event) {
          return _vm.$router.push(item.link);
        }
      }
    }), _c("div", {
      staticClass: "divider-light"
    })], 1);
  }), _c("h2", {
    staticClass: "gray-color"
  }, [_vm._v("Thông tin phương tiện")]), _vm._l(_vm.dataVehicle, function (item) {
    return _c("div", {
      key: item.name
    }, [_c("section-item", {
      attrs: {
        title: item.label,
        required: item.required,
        completed: item.completed
      },
      nativeOn: {
        click: function click($event) {
          return _vm.$router.push(item.link);
        }
      }
    }), _c("div", {
      staticClass: "divider-light"
    })], 1);
  }), _c("el-checkbox", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "align-items": "center",
      "margin-top": "20px"
    },
    attrs: {
      disabled: _vm.$tstore.state.user.info.profileStatus == "SUBMIT",
      indeterminate: false
    },
    model: {
      value: _vm.checkSubmit,
      callback: function callback($$v) {
        _vm.checkSubmit = $$v;
      },
      expression: "checkSubmit"
    }
  }, [_c("span", {
    staticStyle: {
      "white-space": "normal",
      color: "#606266"
    }
  }, [_vm._v("Tôi cam kết hoàn toàn tự chịu trách nhiệm trước pháp luật về toàn bộ các hồ sơ giấy tờ nộp online cho Fclass (gồm: hồ sơ giấy tờ cá nhân của tôi + hồ sơ giấy tờ xe & xe đăng ký trên ứng dụng Mobile App Fclass để vận tải hành khách theo hợp đồng điện tử) là hoàn toàn hợp pháp đúng theo quy định của pháp luật.")]), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Quy tắc ứng xử: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkDriverBehavior
    }
  }, [_vm._v("Xem ở đây")])], 1), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Cam kết tài xế: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkDriverCommit
    }
  }, [_vm._v("Xem ở đây")])], 1), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Quy chế hoạt động: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkRegulation
    }
  }, [_vm._v("Xem ở đây")])], 1)]), _vm.$tstore.state.user.info.profileStatus == "PENDING" ? _c("el-button", {
    staticClass: "btn-save",
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      type: "primary",
      size: "medium",
      disabled: !_vm.checkSubmit || !_vm.checkIdentityComplete || !_vm.checkDriverLicense || !_vm.checkVehicleInfo,
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Gửi hồ sơ")]) : _c("el-button", {
    staticClass: "btn-save",
    staticStyle: {
      "margin-top": "32px"
    },
    attrs: {
      type: "primary",
      size: "medium",
      disabled: "",
      loading: _vm.loading
    }
  }, [_vm._v("Đã gửi hồ sơ")])], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;