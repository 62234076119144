"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.url = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var url = {
  image: function image(url) {
    if (!url) {
      return '';
    }
    if (url.includes('http')) {
      return url;
    }
    return process.env.VUE_APP_BASE_URL_MEDIA + url;
  }
};
exports.url = url;