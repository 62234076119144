"use strict";

var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messages = exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _en = require("./en");
var _vi = require("./vi");
_vue.default.use(_vueI18n.default);
var messages = {
  en: _en.en,
  vi: _vi.vi
};
exports.messages = messages;
var i18n = new _vueI18n.default({
  locale: _jsCookie.default.get("language") || "en",
  fallbackLocale: "vi",
  messages: messages // set locale messages
});
var _default = i18n;
exports.default = _default;