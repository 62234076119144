"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "section-item"
  }, [_c("div", {
    staticClass: "prefix"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", {
    staticClass: "center"
  }, [!_vm.completed ? _c("span", {
    class: {
      required: _vm.required,
      "non-required": !_vm.required
    }
  }, [_vm._v(" " + _vm._s(_vm.required ? "Bắt buộc" : "Không bắt buộc") + " ")]) : _c("span", {
    class: {
      completed: _vm.completed
    }
  }, [_vm._v(" Hoàn tất ")])]), _c("div", {
    staticClass: "suffix"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "chevron-right"
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;