"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.dataForm
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Số điện thoại"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.dataForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "phone", $$v);
      },
      expression: "dataForm.phone"
    }
  })], 1)], 1)], 1), _c("el-form-item", [_c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      loading: _vm.loadingButton,
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" Yêu cầu mã đăng nhập OTP ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;