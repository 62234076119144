"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "identity-card"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("head-section", {
    attrs: {
      title: "Thông tin xe"
    }
  }), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Bảng số Đầu xe",
      prop: "imageVehicleFront"
    }
  }, [_c("SingleImageS3", {
    attrs: {
      pathImage: _vm.form.imageVehicleFront,
      width: 150,
      height: 150
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUpdateSuccess(path, "imageVehicleFront");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Bảng số đuôi xe",
      prop: "imageVehicleBack"
    }
  }, [_c("SingleImageS3", {
    attrs: {
      pathImage: _vm.form.imageVehicleBack,
      width: 150,
      height: 150
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUpdateSuccess(path, "imageVehicleBack");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Biển số",
      prop: "numberPlates"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.numberPlates,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "numberPlates", $$v);
      },
      expression: "form.numberPlates"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhãn hiệu",
      prop: "brandVehicle"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.brandVehicle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "brandVehicle", $$v);
      },
      expression: "form.brandVehicle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số loại",
      prop: "modelVehicle"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.modelVehicle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "modelVehicle", $$v);
      },
      expression: "form.modelVehicle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số chỗ",
      prop: ""
    }
  }, [_c("input-money", {
    model: {
      value: _vm.form.seatVehicle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "seatVehicle", $$v);
      },
      expression: "form.seatVehicle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Màu sơn",
      prop: "colorPaint"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.colorPaint,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "colorPaint", $$v);
      },
      expression: "form.colorPaint"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Năm sản xuất",
      prop: "produceYear"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.produceYear,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "produceYear", $$v);
      },
      expression: "form.produceYear"
    }
  })], 1)], 1), !_vm.$tstore.state.user.info.driverProfile.isSubmitted ? _c("el-button", {
    staticClass: "btn-save",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Lưu")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;