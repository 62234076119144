"use strict";

var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var addressApi = {
  findCity: function findCity() {
    return (0, _request.default)({
      url: "/city"
    });
  },
  updateCity: function updateCity(id, data) {
    return (0, _request.default)({
      url: "/addressCity/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  findDistrict: function findDistrict(params) {
    return (0, _request.default)({
      url: "/district",
      params: params
    });
  },
  findWard: function findWard(params) {
    return (0, _request.default)({
      url: "/ward",
      params: params
    });
  }
};
exports.addressApi = addressApi;