"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "drawer-container"
  }, [_c("div", [_c("h3", {
    staticClass: "drawer-title"
  }, [_vm._v(" Page style setting ")]), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Theme Color")]), _c("theme-picker", {
    staticStyle: {
      float: "right",
      height: "26px",
      margin: "-3px 8px 0 0"
    },
    on: {
      change: _vm.themeChange
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Open Tags-View")]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.tagsView,
      callback: function callback($$v) {
        _vm.tagsView = $$v;
      },
      expression: "tagsView"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Fixed Header")]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.fixedHeader,
      callback: function callback($$v) {
        _vm.fixedHeader = $$v;
      },
      expression: "fixedHeader"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Sidebar Logo")]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.sidebarLogo,
      callback: function callback($$v) {
        _vm.sidebarLogo = $$v;
      },
      expression: "sidebarLogo"
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;