"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrefixImage = void 0;
var PrefixImage;
exports.PrefixImage = PrefixImage;
(function (PrefixImage) {
  PrefixImage["DriverAvatar"] = "driver-avatar";
  PrefixImage["Driver"] = "driver"; //no-compress
})(PrefixImage || (exports.PrefixImage = PrefixImage = {}));