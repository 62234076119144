"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container text-center"
  }, [_c("img", {
    staticStyle: {
      "margin-bottom": "15px",
      "object-fit": "contain"
    },
    attrs: {
      src: require("@/assets/images/logo.png"),
      width: "300px",
      height: "150px",
      alt: ""
    }
  })]), _c("el-form-item", {
    attrs: {
      prop: "username"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "user"
    }
  })], 1), _c("el-input", {
    ref: "username",
    attrs: {
      placeholder: "Username",
      name: "username",
      type: "text",
      tabindex: "1",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "username", $$v);
      },
      expression: "loginForm.username"
    }
  })], 1), _c("el-tooltip", {
    attrs: {
      content: "Caps lock is On",
      placement: "right",
      manual: ""
    },
    model: {
      value: _vm.capsTooltip,
      callback: function callback($$v) {
        _vm.capsTooltip = $$v;
      },
      expression: "capsTooltip"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "password"
    }
  })], 1), _c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: "Password",
      name: "password",
      tabindex: "2",
      autocomplete: "on"
    },
    on: {
      blur: function blur($event) {
        _vm.capsTooltip = false;
      }
    },
    nativeOn: {
      keyup: [function ($event) {
        return _vm.checkCapslock.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.loginForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": _vm.passwordType === "password" ? "eye" : "eye-open"
    }
  })], 1)], 1)], 1), _c("el-button", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v("Login")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;