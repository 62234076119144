"use strict";

var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleGroupApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var vehicleGroupApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/vehicleGroup',
      params: params
    });
  }
};
exports.vehicleGroupApi = vehicleGroupApi;