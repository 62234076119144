"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.string.trim.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.dataForm,
      "label-position": "top"
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cityId",
      label: "Địa chỉ cư trú hiện nay (Tỉnh / thành)"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Tỉnh thành đăng ký dịch vụ"
    },
    on: {
      change: function change($event) {
        _vm.dataForm.districtId = null;
      }
    },
    model: {
      value: _vm.dataForm.cityId,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "cityId", $$v);
      },
      expression: "dataForm.cityId"
    }
  }, _vm._l(_vm.cities, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "districtId"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Địa chỉ cư trú hiện nay "), _c("br"), _vm._v(" (Quận / huyện)")]), _c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Quận huyện đăng ký dịch vụ"
    },
    on: {
      change: function change($event) {
        _vm.dataForm.wardId = null;
      }
    },
    model: {
      value: _vm.dataForm.districtId,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "districtId", $$v);
      },
      expression: "dataForm.districtId"
    }
  }, _vm._l(_vm.districts, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "wardId"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Địa chỉ cư trú hiện nay "), _c("br"), _vm._v(" (Phường / xã)")]), _c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Phường xã đăng ký dịch vụ"
    },
    model: {
      value: _vm.dataForm.wardId,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "wardId", $$v);
      },
      expression: "dataForm.wardId"
    }
  }, _vm._l(_vm.wards, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Loại dịch vụ",
      prop: "vehicleGroupId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.dataForm.vehicleGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "vehicleGroupId", $$v);
      },
      expression: "dataForm.vehicleGroupId"
    }
  }, _vm._l(_vm.vehicleGroups, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _vm.dataForm.vehicleGroupId ? _c("el-form-item", {
    attrs: {
      prop: "vehicleIds",
      label: "Dịch vụ đăng ký"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Dịch vụ",
      filterable: "",
      "reserve-keyword": "",
      multiple: ""
    },
    model: {
      value: _vm.dataForm.vehicleIds,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "vehicleIds", $$v);
      },
      expression: "dataForm.vehicleIds"
    }
  }, _vm._l(_vm.services, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " ").concat(item.subName ? " | " + item.subName : ""),
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "name",
      label: "Họ tên"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Họ tên"
    },
    model: {
      value: _vm.dataForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "name", $$v);
      },
      expression: "dataForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "phone",
      label: "Số điện thoại"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Số điện thoại"
    },
    model: {
      value: _vm.dataForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "phone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "dataForm.phone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "email",
      label: "Email"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Email"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mã giới thiệu (Tổ chức)"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Mã giới thiệu"
    },
    model: {
      value: _vm.dataForm.refCodeCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "refCodeCompany", $$v);
      },
      expression: "dataForm.refCodeCompany"
    }
  })], 1), _c("el-form-item", [_c("el-checkbox", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "align-items": "center"
    },
    attrs: {
      indeterminate: false
    },
    model: {
      value: _vm.dataForm.checkSubmit,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "checkSubmit", $$v);
      },
      expression: "dataForm.checkSubmit"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      "white-space": "normal",
      color: "#606266"
    }
  }, [_vm._v("Tôi cam kết hoàn toàn tự chịu trách nhiệm trước pháp luật về toàn bộ các hồ sơ giấy tờ nộp online cho Fclass (gồm: hồ sơ giấy tờ cá nhân của tôi + hồ sơ giấy tờ xe & xe đăng ký trên ứng dụng Mobile App Fclass để vận tải hành khách theo hợp đồng điện tử) là hoàn toàn hợp pháp đúng theo quy định của pháp luật.")]), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Quy tắc ứng xử: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkDriverBehavior
    }
  }, [_vm._v("Xem ở đây")])], 1), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Cam kết tài xế: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkDriverCommit
    }
  }, [_vm._v("Xem ở đây")])], 1), _c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("Quy chế hoạt động: ")]), _c("el-link", {
    attrs: {
      type: "primary",
      target: "_blank",
      href: _vm.linkRegulation
    }
  }, [_vm._v("Xem ở đây")])], 1)])])], 1), _c("el-form-item", [_c("el-button", {
    staticStyle: {
      "font-weight": "bold",
      width: "100%"
    },
    attrs: {
      loading: _vm.loadingButton,
      type: "primary",
      disabled: !_vm.dataForm.checkSubmit
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" Đăng ký ngay ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;