"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "otp-page"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("img", {
    staticStyle: {
      "border-radius": "8px"
    },
    attrs: {
      src: _vm.logo,
      width: "80"
    }
  }), _c("div", [_c("h2", [_vm._v("Xác thực")]), _c("div", {
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c("p", [_vm._v("Hệ thống đã gửi mã xác nhận tới số điện thoại")]), _c("b", [_vm._v(_vm._s(_vm.$tstore.state.user.phone))])])]), _c("div", {
    staticClass: "otp-container"
  }, [_c("v-otp-input", {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      separator: " ",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-complete": _vm.handleOnComplete
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm.countdown ? _c("span", [_vm._v(" Có thể yêu cầu gửi lại mã xác nhận trong " + _vm._s(_vm.formatCountdown(_vm.countdown)) + " ")]) : _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.fetchOtp
    }
  }, [_vm._v(" Yêu cầu hệ thống gửi mã xác nhận mới ")])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;