"use strict";

var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;
exports.secondToMinutes = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.number.to-fixed.js");
var _moment = _interopRequireDefault(require("moment"));
require("moment-duration-format");
function formatCurrency(n) {
  return new Intl.NumberFormat('Vi', {
    style: 'currency',
    currency: 'VND'
  }).format(n);
}
function formatNumberVN(n) {
  var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (isNaN(n)) {
    return '--';
  }
  return (+n || 0).toFixed(fixed).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
}
var secondToMinutes = function secondToMinutes(seconds) {
  var hour = Math.floor(_moment.default.duration(seconds, 'seconds').asHours());
  var minute = _moment.default.duration(seconds, 'seconds').format('mm');
  var second = _moment.default.duration(seconds, 'seconds').seconds();
  if (second < 10) {
    second = "0".concat(second);
  }
  if (seconds > 3600) {
    return hour + ':' + minute + ':' + second;
  }
  return minute + ':' + second;
};
exports.secondToMinutes = secondToMinutes;