"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "head-section"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "chevron-left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.replace("/application");
      }
    }
  }), _c("span", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;