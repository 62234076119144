"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = formatDate;
exports.formatDateDay = formatDateDay;
exports.formatDateTime = formatDateTime;
var moment = require("moment");
function formatDate(timestamp) {
  return moment.unix(timestamp).format("DD/MM/YYYY");
}
function formatDateTime(timestamp) {
  return moment.unix(timestamp).format("HH:mm:ss, DD/MM/YYYY");
}
function formatDateDay(timestamp) {
  return moment.unix(timestamp).format("dddd, MM/DD/YYYY");
}