"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", [_c("img", {
    staticStyle: {
      "border-radius": "8px"
    },
    attrs: {
      src: _vm.logo,
      alt: "",
      width: "80"
    }
  })]), _c("div", [_c("el-link", {
    on: {
      click: _vm.logout
    }
  }, [_vm._v("Đăng xuất")])], 1)]), _vm._m(0)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("p", {
    staticClass: "gray-color"
  }, [_vm._v(" Hoàn tất mẫu đơn đăng ký và bắt đầu lái xe với Fclass! "), _c("br"), _vm._v(" Hãy cung cấp thông tin sau: ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;