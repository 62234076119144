"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "home-page"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("img", {
    staticStyle: {
      display: "block",
      margin: "0 auto",
      "border-radius": "8px"
    },
    attrs: {
      src: _vm.logo,
      width: "80"
    }
  }), _c("el-tabs", {
    attrs: {
      stretch: ""
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      lazy: true,
      label: "Đăng ký"
    }
  }, [_c("Register")], 1), _c("el-tab-pane", {
    attrs: {
      lazy: true,
      label: "Đăng nhập"
    }
  }, [_c("Login")], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;