"use strict";

var _interopRequireDefault = require("C:/Projects/028 - Driver/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.driverApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var driverApi = {
  create: function create(data) {
    return (0, _request.default)({
      url: '/driver',
      method: 'post',
      data: data
    });
  },
  login: function login(data) {
    return (0, _request.default)({
      url: '/driver/login',
      method: 'post',
      data: data
    });
  },
  updateProfile: function updateProfile(data) {
    return (0, _request.default)({
      baseURL: process.env.VUE_APP_BASE_URL_MEDIA + '/v1/driver',
      url: '/profile/update',
      method: 'post',
      data: data
    });
  },
  submitProfile: function submitProfile() {
    return (0, _request.default)({
      url: "/driver/profile/submit",
      method: 'post'
    });
  },
  update: function update(data) {
    return (0, _request.default)({
      baseURL: process.env.VUE_APP_BASE_URL_MEDIA + '/v1/driver',
      url: '/update',
      method: 'post',
      data: data
    });
  },
  getInfo: function getInfo() {
    return (0, _request.default)({
      baseURL: process.env.VUE_APP_BASE_URL_MEDIA + '/v1/driver',
      url: '/myself/info'
    });
  },
  checkExist: function checkExist(data) {
    return (0, _request.default)({
      url: '/driver/phone/exist',
      method: 'post',
      data: data
    });
  }
};
exports.driverApi = driverApi;