"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "bio-detail"
  }, [_c("div", {
    staticClass: "application-content"
  }, [_c("head-section", {
    attrs: {
      title: "Ảnh chân dung"
    }
  }), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "avatar",
      label: "Cập nhật hình nền trên ứng dụng"
    }
  }, [_c("SingleImageS3", {
    attrs: {
      width: 150,
      height: 150,
      pathImage: _vm.form.avatar,
      prefixFile: _vm.PrefixImage.DriverAvatar
    },
    on: {
      "upload:success": _vm.uploadSuccess
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giới tính",
      prop: "gender"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, _vm._l(_vm.Gender, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: _vm.GenderTrans[item],
        value: item
      }
    });
  }), 1)], 1)], 1), !_vm.$tstore.state.user.info.driverProfile.isSubmitted ? _c("el-button", {
    staticClass: "btn-save",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Lưu")]) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;